import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDateToLongString } from "../util/dateUtils";

const JournalList = () => {
  const [entries, setEntries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/data/journalList.json")
      .then((response) => response.json())
      .then((data) => {
        // Sorting the items by time with recent item at the top
        const sortedData = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setEntries(sortedData);
      })
      .catch((error) => console.error("Error fetching journal list:", error));
  }, []);

  return (
    <Container>
      <Grid container spacing={4}>
        {entries.map((entry) => (
          <Grid item xs={12} sm={6} md={6} key={entry.id}>
            <Card>
              <CardActionArea
                onClick={() =>
                  navigate(`/journal/${entry.id}`, {
                    state: { entry: entry },
                  })
                }
              >
                {/* FIXME - Analyze and fix why the images could not be loaded from pCloud */}
                <CardMedia
                  component="img"
                  height="200"
                  image={entry.preview}
                  alt={entry.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {entry.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {entry.description}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    {formatDateToLongString(entry.date)}
                  </Typography>
                  {entry.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      size="small"
                      style={{ marginRight: 4 }}
                    />
                  ))}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default JournalList;
