import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { formatDateToLongString } from "../util/dateUtils";

const JournalDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const [entry, setEntry] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    // Get the detailFile from the location state or construct it
    const entry = location.state?.entry || `entry${id}.json`;
    setEntry(entry);

    fetch(`/data/${entry.detailFile}`)
      .then((response) => response.text())
      .then((data) => setContent(data))
      .catch((error) => console.error("Error fetching journal entry:", error));
  }, [id, location.state]);

  if (!entry) {
    return <Typography>Loading...</Typography>;
  }

  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {entry.title}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {formatDateToLongString(entry.date)}
      </Typography>
      <CardMedia component="img" image={entry.preview} alt={entry.title} />
      <Box
        sx={{ my: 2 }}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
      <Grid2 container spacing={2}>
        {entry.photos.map((photo, index) => (
          <Grid2 item xs={12} sm={6} key={index}>
            <Card>
              <CardMedia
                component="img"
                image="https://api.pcloud.com/getpubthumb?code=XZvslv0ZfTxV6b4VR00YpH0cBXnx6mOc71QV&type=public"
                alt={`Photo ${index + 1}`}
              />
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default JournalDetail;
