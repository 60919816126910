import { Box, Container, Typography } from "@mui/material";
import React from "react";

const comments = [
  {
    user: "Gajapathy R",
    note: "Happy Birthday Hanish",
  },
  {
    user: "Sathya Lingaraj",
    note: "Happy Birthday Hanish",
  },
  {
    user: "Lingaraj",
    note: "Happy Birthday to the little Boy Hanish",
  },
  {
    user: "Danuja",
    note: "Happy Birthday Hanish",
  },
  {
    user: "Arjun",
    note: "Happy Birthday Hanish!!!",
  },
  {
    user: "Shanmugapriya",
    note: "Wishes and prayers",
  },
  {
    user: "Lokesh",
    note: "Happy Birthday Hanish",
  },
  {
    user: "Sangeetha Lingaraj",
    note: "Happy Birthday lil Charm.... a big hug and lots of love.",
  },
  {
    user: "Sindhu Prakash",
    note: "Happy Birthday Hanish. Good Bless.",
  },
  {
    user: "Rudhrappan",
    note: "Happy birthday Hanish. God bless you.",
  },
  {
    user: "Nandhini Vignesh",
    note: "Happy Birthday to Kutty.",
  },
  {
    user: "SriDevi",
    note: "Happy Happy Birthday Hanish",
  },
  {
    user: "Sarangan",
    note: "Happy Birthday Hanish ...!",
  },
  {
    user: "Pushparaj",
    note: "Happy Bday Hanish",
  },
  {
    user: "Gayathri",
    note: "Many more happy returns of the day Hanish!",
  },
  {
    user: "Kathir",
    note: "Happy Birthday Hanish",
  },
  {
    user: "Barani",
    note: "Happy happy birthday smart champ Hanish",
  },
  {
    user: "Trisha",
    note: "Happy birthday hani",
  },
  {
    user: "Nithya",
    note: "Happy Birthday little boy Hanish",
  },
  {
    user: "Vithya",
    note: "Happy Birthday Hani",
  },
  {
    user: "Sivakumar",
    note: "Happy Birthday Hanish",
  },
  {
    user: "Pankaja Patti",
    note: "With you Happy Birthday Hanish. God Bless you",
  },
  {
    user: "Neela",
    note: "Many more happy returns of the day Hanish kutty",
  },
  {
    user: "Jothish",
    note: "Happy Birthday Hanish kutty 🎊🥳",
  },
  {
    user: "Ruthresh",
    note: "Many more happy returns of the day Hanish kutty 🥳🤩🧁",
  },
  {
    user: "Santhosh",
    note: "Happy Birthday Hanish kutty!!! 🎁🎉🎉",
  },
  {
    user: "Uma Patti",
    note: "Happy Birthday Hanish kutty 🎂❤️. God bless you",
  },
];

const HomeComments = () => {
  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Wishes from Family and Friends
      </Typography>
      {comments.map((comment, index) => (
        <Box key={index} className="comment" sx={{ textAlign: "left", mb: 2 }}>
          <Typography variant="body1">
            <strong>{comment.user}:</strong> {comment.note}
          </Typography>
        </Box>
      ))}
    </Container>
  );
};

export default HomeComments;
